import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
					<path d="M5145 10244 c-386 -23 -801 -97 -1150 -205 -139 -43 -310 -102 -360
-123 -16 -8 -82 -35 -145 -62 -160 -68 -513 -244 -530 -265 -3 -3 -21 -14 -40
-24 -59 -31 -354 -228 -435 -292 -5 -5 -30 -24 -55 -43 -43 -32 -229 -188
-240 -200 -3 -3 -49 -45 -103 -94 -116 -105 -365 -372 -448 -481 -13 -16 -26
-32 -29 -35 -3 -3 -17 -21 -31 -40 -15 -19 -35 -45 -45 -57 -36 -42 -180 -255
-267 -396 -111 -178 -283 -524 -364 -727 -165 -418 -261 -798 -319 -1260 -22
-178 -31 -722 -14 -915 41 -495 149 -952 337 -1429 83 -212 169 -388 313 -641
135 -237 349 -534 545 -755 51 -58 376 -383 435 -436 623 -553 1428 -949 2272
-1119 114 -22 406 -63 543 -74 154 -14 614 -14 770 -1 174 15 444 53 570 80
170 37 248 56 390 97 536 155 1035 398 1500 729 33 24 65 47 70 51 6 5 30 24
55 43 25 19 47 37 50 40 3 3 26 22 52 43 26 21 53 43 60 50 7 7 67 60 133 119
174 154 370 363 525 558 29 36 60 74 69 86 43 50 232 329 282 416 23 40 45 75
48 78 19 16 212 403 265 531 26 63 51 123 56 134 24 53 91 244 128 365 93 307
152 602 194 975 16 146 16 713 0 875 -42 415 -136 835 -269 1200 -84 229 -224
542 -312 695 -95 166 -188 319 -218 360 -5 6 -23 33 -42 60 -160 233 -329 434
-561 666 -385 386 -730 640 -1210 892 -65 34 -135 67 -315 147 -195 87 -530
198 -770 255 -84 20 -192 44 -221 50 -52 11 -310 47 -429 61 -123 14 -612 26
-740 18z m745 -49 c462 -55 771 -126 1162 -266 78 -28 164 -61 192 -74 28 -13
78 -34 112 -48 188 -79 519 -262 739 -410 503 -336 955 -789 1307 -1307 132
-196 319 -535 407 -740 18 -41 41 -93 51 -115 57 -127 183 -517 221 -685 63
-278 83 -394 115 -660 25 -207 25 -742 0 -965 -115 -1036 -502 -1928 -1171
-2700 -129 -149 -387 -400 -556 -540 -26 -22 -190 -148 -234 -180 -209 -152
-393 -264 -645 -393 -135 -69 -195 -97 -335 -157 -28 -12 -77 -31 -215 -84
-245 -93 -618 -186 -920 -230 -313 -46 -403 -52 -725 -52 -295 0 -404 8 -705
51 -688 100 -1346 351 -1934 737 -124 82 -356 252 -422 310 -18 16 -56 48 -85
73 -99 82 -378 358 -460 455 -19 22 -38 45 -44 51 -5 6 -26 30 -45 54 -19 24
-40 48 -45 54 -22 23 -193 255 -251 341 -393 581 -664 1281 -763 1970 -41 281
-51 427 -51 722 0 244 4 322 26 513 51 452 126 768 282 1190 114 307 302 677
483 946 58 86 122 179 128 184 4 3 14 16 23 30 9 14 38 53 64 87 27 33 63 78
79 99 144 185 476 517 675 674 24 19 58 46 75 61 103 87 409 293 555 376 425
241 809 396 1255 508 265 67 589 119 845 135 179 12 662 3 810 -15z"/>
<path d="M6281 7975 c-89 -190 -106 -221 -148 -262 -113 -109 -206 -110 -473
-3 -80 32 -165 67 -190 78 -221 99 -554 138 -795 93 -140 -27 -175 -36 -289
-83 -321 -131 -547 -382 -637 -708 -21 -74 -24 -107 -24 -255 0 -182 12 -257
63 -400 38 -109 131 -282 192 -358 142 -178 302 -323 475 -430 68 -42 54 -48
-50 -22 -98 25 -334 31 -451 11 -107 -18 -265 -64 -317 -93 -47 -26 -146 -102
-193 -148 -48 -46 -148 -171 -176 -218 -74 -127 -135 -287 -159 -417 -17 -91
-16 -332 0 -415 92 -457 411 -862 871 -1107 621 -330 1417 -326 1932 11 97 63
124 85 226 189 271 276 415 653 399 1047 -8 207 -53 377 -143 551 -141 269
-405 507 -745 672 -106 51 -367 152 -394 152 -13 0 -15 -38 -15 -278 l0 -277
28 -11 c109 -45 146 -61 216 -96 173 -87 334 -202 431 -310 56 -62 130 -172
151 -226 8 -20 19 -46 24 -57 47 -101 61 -378 27 -505 -38 -137 -122 -327
-160 -359 -27 -22 -284 -44 -457 -38 -129 4 -376 30 -387 41 -1 1 -3 115 -4
254 -1 139 -6 255 -10 258 -5 3 -37 -11 -72 -30 -96 -54 -168 -69 -319 -64
l-128 3 0 -70 c-1 -165 -34 -267 -113 -346 -42 -42 -117 -87 -175 -104 -13 -4
-21 -9 -19 -12 3 -2 361 -4 796 -3 601 0 791 -2 791 -11 0 -7 -34 -45 -76 -85
-168 -163 -394 -277 -684 -346 -84 -20 -121 -22 -320 -23 -198 0 -239 3 -340
24 -217 44 -405 117 -575 223 -122 76 -143 92 -235 176 -231 210 -366 430
-431 703 -34 143 -32 366 4 507 49 191 145 360 278 492 158 157 334 222 599
222 154 0 236 -17 355 -76 74 -37 172 -116 221 -179 16 -21 34 -43 41 -51 26
-29 86 -164 105 -235 29 -113 23 -295 -16 -406 -34 -100 -94 -191 -153 -232
l-46 -32 28 -7 c50 -12 226 3 293 25 129 44 190 108 238 251 27 78 29 97 29
225 -1 132 -2 145 -33 230 l-33 89 1 915 c0 503 3 918 7 922 5 5 51 16 103 26
131 24 461 25 615 0 132 -20 355 -75 371 -91 6 -6 26 -51 44 -101 18 -49 37
-94 42 -100 4 -5 8 33 8 84 0 109 -7 107 131 32 138 -77 290 -205 389 -330
119 -151 216 -356 274 -580 56 -217 70 -335 70 -611 0 -267 -10 -362 -55 -548
-74 -308 -255 -603 -462 -751 -33 -25 -49 -46 -66 -89 -12 -31 -36 -87 -54
-124 -17 -37 -28 -70 -26 -73 7 -7 290 74 299 85 3 3 32 17 65 30 71 29 219
109 223 121 2 5 8 9 14 9 22 0 133 96 241 207 158 164 303 442 357 688 39 175
50 275 50 471 0 276 -32 474 -110 680 -88 231 -189 392 -345 549 -244 247
-515 391 -910 483 l-80 19 -5 484 c-4 425 -7 480 -19 454z m-1081 -180 c241
-62 428 -165 623 -345 62 -58 225 -253 248 -298 8 -15 20 -33 27 -41 23 -24 7
-36 -49 -35 -30 0 -313 5 -629 11 -583 11 -1126 7 -1136 -8 -3 -5 2 -9 11 -9
32 0 132 -60 172 -103 99 -107 117 -189 109 -502 -4 -126 -6 -233 -6 -238 0
-21 -72 35 -159 121 -115 116 -119 120 -181 229 -76 132 -130 326 -130 464 0
74 25 221 50 294 81 240 270 405 535 470 88 21 116 23 270 20 113 -2 160 -8
245 -30z"/>
<path d="M2254 2580 c-31 -16 -44 -30 -54 -59 -18 -56 6 -94 78 -121 29 -11
59 -20 66 -20 8 0 19 -7 27 -16 32 -39 -50 -42 -122 -5 l-39 21 -15 -30 c-8
-16 -15 -35 -15 -43 0 -22 68 -48 140 -54 79 -7 126 11 154 58 38 65 -2 123
-105 154 -46 14 -59 23 -59 38 0 17 5 19 43 13 23 -4 52 -14 64 -22 30 -19 30
-19 53 26 l20 39 -32 17 c-18 9 -62 19 -98 21 -52 3 -73 0 -106 -17z"/>
<path d="M2520 2425 l0 -175 55 0 55 0 0 65 0 65 55 0 55 0 0 -65 0 -65 55 0
55 0 0 176 0 175 -52 -3 -53 -3 -1 -65 -1 -65 -57 -3 -56 -3 0 71 0 70 -55 0
-55 0 0 -175z"/>
<path d="M3003 2573 c-7 -16 -30 -71 -51 -123 -22 -52 -49 -117 -61 -143 -11
-27 -21 -51 -21 -53 0 -2 25 -4 55 -4 50 0 55 2 61 25 6 24 10 25 79 25 69 0
73 -1 79 -25 6 -23 11 -25 66 -25 67 0 66 -7 22 93 -11 23 -38 90 -62 150
l-43 107 -56 0 c-52 0 -57 -2 -68 -27z m90 -148 l16 -45 -40 0 c-21 0 -39 1
-39 3 0 18 34 96 40 92 4 -3 15 -25 23 -50z"/>
<path d="M3290 2598 c-1 -2 -2 -81 -3 -176 l-2 -172 48 0 47 0 0 90 c0 50 3
90 8 90 4 0 18 -26 31 -58 33 -76 38 -82 77 -82 32 0 35 4 68 73 l34 72 1 -92
1 -93 50 0 50 0 0 175 0 175 -58 0 -59 0 -42 -91 c-24 -53 -46 -88 -51 -83 -5
5 -20 34 -35 64 -52 108 -48 105 -109 108 -31 2 -56 2 -56 0z"/>
<path d="M3815 2585 c-44 -23 -65 -53 -65 -92 0 -48 43 -87 117 -105 46 -11
58 -18 58 -33 0 -29 -35 -30 -103 -2 l-60 24 -16 -34 c-9 -18 -16 -36 -16 -38
0 -14 92 -48 140 -52 104 -10 170 32 170 107 0 48 -27 73 -111 103 -39 14 -64
29 -64 38 0 19 62 17 105 -5 18 -9 34 -15 35 -14 7 9 35 68 35 75 0 33 -175
55 -225 28z"/>
<path d="M4072 2468 c3 -124 5 -135 27 -160 36 -41 100 -62 164 -55 44 5 58
12 90 46 l37 40 0 121 c0 66 0 123 0 128 0 4 -25 8 -55 10 l-55 3 0 -119 c0
-125 -6 -142 -46 -142 -46 0 -54 23 -54 146 l0 114 -56 0 -56 0 4 -132z"/>
<path d="M4440 2426 l0 -176 98 0 c101 0 150 15 197 57 50 45 60 141 22 208
-34 61 -80 79 -209 83 l-108 4 0 -176z m171 84 c56 -16 78 -91 43 -145 -13
-20 -25 -25 -60 -25 l-44 0 0 83 c0 104 0 104 61 87z"/>
<path d="M4810 2426 l0 -176 83 0 c140 1 192 22 234 96 27 47 30 112 7 157
-37 70 -80 89 -216 95 l-108 5 0 -177z m203 64 c10 -9 21 -35 24 -57 7 -52
-26 -94 -77 -96 l-35 -2 -3 79 c-2 44 0 86 3 93 7 19 63 8 88 -17z"/>
<path d="M5180 2425 l0 -176 148 3 147 3 0 35 c0 23 -5 35 -15 37 -8 1 -50 5
-92 8 -68 6 -78 9 -78 26 0 17 8 19 80 19 l80 0 0 45 0 45 -81 0 c-78 0 -80 1
-77 23 3 21 8 22 91 25 l87 3 0 39 0 40 -145 0 -145 0 0 -175z"/>
<path d="M5520 2425 l0 -175 146 0 145 0 -3 42 -3 42 -85 1 c-80 0 -85 1 -88
23 -3 21 -1 22 77 22 l81 0 0 45 0 45 -79 0 c-79 0 -95 7 -83 38 3 8 31 12 94
12 l89 0 -3 38 -3 37 -142 3 -143 3 0 -176z"/>
<path d="M5859 2458 c-1 -79 -2 -156 -3 -173 -1 -29 1 -30 47 -33 l47 -3 1 53
c3 102 4 111 14 114 6 1 37 -34 71 -79 60 -80 62 -82 107 -85 l47 -3 0 175 0
176 -50 0 -49 0 -3 -92 -3 -92 -70 92 c-68 91 -69 92 -112 92 l-43 0 -1 -142z"/>
<path d="M6235 2588 c-3 -7 -4 -86 -3 -173 l3 -160 84 -3 c96 -3 161 14 204
54 35 32 47 63 47 119 0 71 -22 110 -85 150 -40 25 -241 36 -250 13z m173 -79
c18 -6 35 -22 42 -40 13 -30 8 -95 -8 -111 -12 -12 -83 -28 -94 -22 -10 6 -11
168 -1 177 9 10 25 9 61 -4z"/>
<path d="M6689 2553 c-12 -26 -38 -88 -59 -138 -21 -49 -45 -107 -54 -127
l-16 -38 55 0 c50 0 56 2 65 25 9 23 14 25 80 25 66 0 71 -2 80 -25 9 -23 15
-25 65 -25 30 0 55 1 55 3 0 4 -79 198 -90 222 -5 11 -18 43 -29 70 l-19 50
-56 3 -56 3 -21 -48z m90 -110 c8 -21 16 -44 18 -50 4 -9 -7 -13 -37 -13 -29
0 -40 4 -36 13 2 6 10 29 17 50 7 20 15 37 19 37 3 0 12 -17 19 -37z"/>
<path d="M6920 2555 l0 -45 50 0 50 0 0 -130 0 -130 55 0 55 0 0 129 0 130 48
3 47 3 0 40 0 40 -152 3 -153 3 0 -46z"/>
<path d="M7336 2591 c-3 -5 -36 -78 -72 -163 -36 -84 -68 -159 -71 -165 -4
-10 10 -13 51 -13 51 0 57 2 66 25 9 25 13 26 82 23 67 -3 73 -5 76 -25 3 -21
9 -23 63 -23 l59 0 -17 43 c-9 23 -40 101 -69 172 l-52 130 -55 3 c-30 2 -58
-1 -61 -7z m72 -143 c6 -18 13 -41 17 -50 6 -15 1 -18 -34 -18 -23 0 -41 1
-41 3 0 16 34 97 40 97 5 0 13 -15 18 -32z"/>
<path d="M7683 2589 c-40 -12 -73 -57 -73 -99 0 -45 45 -84 117 -102 39 -9 59
-19 61 -31 6 -29 -29 -32 -96 -8 -35 12 -65 21 -66 19 -20 -27 -36 -54 -36
-60 0 -16 87 -50 140 -55 104 -10 170 32 170 107 0 47 -26 72 -110 104 -73 27
-87 52 -29 50 20 -1 51 -8 69 -18 18 -9 34 -15 35 -14 10 16 36 80 33 83 -1 1
-25 10 -51 19 -52 17 -114 19 -164 5z"/>
<path d="M7932 2466 c3 -117 6 -137 23 -156 69 -76 197 -79 265 -6 24 26 25
35 28 162 l4 135 -54 -3 -53 -3 -2 -113 c-2 -120 -9 -142 -49 -142 -46 0 -54
22 -54 146 l0 114 -56 0 -56 0 4 -134z"/>
<path d="M8300 2425 l0 -175 103 0 c121 0 169 11 197 47 29 38 26 79 -10 113
l-29 27 24 23 c51 48 17 120 -63 134 -20 3 -79 6 -129 6 l-93 0 0 -175z m196
80 c10 -24 -15 -45 -52 -45 -31 0 -34 3 -34 30 0 28 2 30 40 30 27 0 43 -5 46
-15z m8 -131 c10 -26 -13 -44 -55 -44 -37 0 -39 2 -39 30 0 29 2 30 44 30 31
0 46 -5 50 -16z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
